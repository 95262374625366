export const brands = [
  {
    id: 1,
    name: "Fitbit",
    brandCode: "fitbit",
    metadata: "{}",
    createdAt: "2022-10-21T01:01:28.016Z",
    updatedAt: "2022-10-21T01:01:28.016Z",
    deletedAt: null,
  },
  {
    id: 2,
    name: "Withings",
    brandCode: "withings",
    metadata: "{}",
    createdAt: "2022-11-02T23:54:28.016Z",
    updatedAt: "2022-11-02T23:54:28.016Z",
    deletedAt: null,
  },
  {
    id: 3,
    name: "Polar",
    brandCode: "polar",
    metadata: "{}",
    createdAt: "2022-11-07T09:27:32.341Z",
    updatedAt: "2022-11-07T09:27:32.341Z",
    deletedAt: null,
  },
  {
    id: 4,
    name: "Garmin",
    brandCode: "garmin",
    metadata: "{}",
    createdAt: "2022-11-07T09:31:21.412Z",
    updatedAt: "2022-11-07T09:31:21.412Z",
    deletedAt: null,
  },
  {
    id: 5,
    name: "Google",
    brandCode: "google-fit",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 6,
    name: "Apple",
    brandCode: "apple",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 7,
    name: "Freestyle Libre",
    brandCode: "freestyle",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 8,
    name: "Huawei Health",
    brandCode: "huawei",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 9,
    name: "Omron",
    brandCode: "omron",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 10,
    name: "Oura",
    brandCode: "oura",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 11,
    name: "Samsung",
    brandCode: "samsung",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
  {
    id: 12,
    name: "Whoop",
    brandCode: "whoop",
    metadata: "{}",
    createdAt: "2022-11-18T12:58:21.412Z",
    updatedAt: "2022-11-18T12:58:21.412Z",
    deletedAt: null,
  },
]
