import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Nav from "../../components/nav"
import BrandCard from "../../components/brandCard"
import Layout from "../../components/layout"
import Api from "../../network/api"
import Loading from "../../components/loading"
import { useParams } from "react-router-dom"
import Search from "../../components/search"
import WhyVivantaInvolved from "../../components/whyVivantaInvolved"

const ChooseBrand = () => {
  const api = new Api()
  const { t } = useTranslation()
  const { brandId } = useParams()
  const [loading, setLoading] = useState(false)
  const [userBrands, setUserBrands] = useState([])

  const isMobile = localStorage.getItem("isMobile")

  useEffect(() => {
    async function getBrands() {
      setLoading(true)
      if (brandId) {
        __getBrandLinkAndRedirect(brandId)
        return
      }
      const brands = await api.getBrands()
      setUserBrands(brands)
      setLoading(false)
    }
    getBrands()
  }, [])

  const __getBrandLinkAndRedirect = async id => {
    setLoading(true)
    const result = await api.getBrandLink(id)
    if (result !== undefined && result.authorization_url) {
      document.location.href = result.authorization_url
    }
    setLoading(false)
  }

  const [search, setSearch] = useState("")

  const externalId = localStorage.getItem("externalId")
  const publicKey = localStorage.getItem("publicKey")
  const customerId = localStorage.getItem("customerId")

  const filteredBrands =
    search !== ""
      ? userBrands.filter(brand =>
          brand.name.toLowerCase().includes(search.toLowerCase())
        )
      : userBrands

  if (loading) return <Loading />

  return (
    <Layout>
      <React.Fragment>
        <Nav
          back={
            publicKey
              ? `/start/${publicKey}/${customerId}/${externalId}${
                  isMobile === "1" ? "" : "?web=true"
                }`
              : "/start"
          }
          title={t("brands.title")}
          searchPlaceholder={t("brands.search")}
          searchValue={search}
          onSearch={text => setSearch(text)}
        />
        <div className="brand-container">
          <div className="row mt-2">
            <div className="col-12 mb-3">
              <Search
                searchPlaceholder={t("brands.search")}
                searchValue={search}
                onSearch={text => setSearch(text)}
              />
            </div>
            {filteredBrands.map(brand =>
              brand.id === 6 || brand.id === 8 ? (
                <></>
              ) : (
                <div className="col-6" key={`brand-card-${brand.id}`}>
                  <BrandCard
                    id={brand.id}
                    image={brand.image}
                    title={brand.name}
                    handleClick={__getBrandLinkAndRedirect}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <WhyVivantaInvolved />
        <style jsx="true">
          {`
            .brand-container {
              margin-right: 0.5rem;
            }
          `}
        </style>
      </React.Fragment>
    </Layout>
  )
}

export default ChooseBrand
