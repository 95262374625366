import React from "react"
import Start from "./pages/start"
import IntroDemo from "./pages/demo/introDemo"
import ChooseBrand from "./pages/choose-brand"
import BrandsDemo from "./pages/demo/brandsDemo"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import IntegrationSuccess from "./pages/integration-success"
import SuccessDemo from "./pages/demo/successDemo"
import BrandRedirect from "./pages/brand-redirect"
import Privacy from "./pages/privacy"
import Landing from "./pages/landing"
import SignedLanding from "./pages/signed-landing"
import AboutVivanta from "./pages/about-vivanta"

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Demo / Sandbox screens */}
        <Route path={"/"} exact element={<IntroDemo />} />
        <Route path={"/brands"} exact element={<BrandsDemo />} />
        <Route path={"/success/:brand"} extact element={<SuccessDemo />} />
        {/* Dev / Production screens */}
        <Route
          path={"/start/:publicKey/:customerId/:externalUserId"}
          exact
          element={<Landing />}
        />
        <Route path={"/start"} exact element={<Start />} />
        <Route path={"/privacy"} exact element={<Privacy />} />
        <Route
          path={"/choose-brand/:brandId"}
          exact
          element={<ChooseBrand />}
        />
        <Route path={"/choose-brand"} exact element={<ChooseBrand />} />
        <Route
          path={"/redirect/:brandCode/:code"}
          exact
          element={<BrandRedirect />}
        />
        <Route
          path={"/redirect/:brandCode/:code/:code2"}
          exact
          element={<BrandRedirect />}
        />
        <Route
          path={"/integration-success/:brand"}
          extact
          element={<IntegrationSuccess />}
        />
        <Route
          path={"/native/:publicKey/:customerId/:externalUserId/:brandId"}
          exact
          element={<Landing />}
        />
        <Route
          path={"/native-with-token/:token/:brandId"}
          exact
          element={<SignedLanding />}
        />
        <Route
          path={"/start-signed/:token"}
          exact
          element={<SignedLanding />}
        />
        <Route
          path={"/start-signed/:token/:brandId"}
          exact
          element={<SignedLanding />}
        />
        <Route path={"/about-vivanta"} exact element={<AboutVivanta />} />
        <Route path="*" exact={true} component={<IntroDemo />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
