import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../components/layout"
import vivantaLogo from "../../images/vivanta-logo.png"
import success from "../../images/success-integration.png"

const SuccessDemo = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Fragment>
        <div className="flex-grow-1">
          <div className="row">
            <div className="col-8 offset-2">
              <img src={vivantaLogo} alt="Vivanta" className="img-fluid logo" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <h3 className="text-center">
                <strong>Demo Client</strong>
                {t("success.message", {
                  brandName: "device",
                })}
              </h3>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6 offset-3">
              <img src={success} alt="Success" className="img-fluid" />
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .logo {
            margin-top: 84px;
            margin-bottom: 24px;
          }
          .outside-icon {
            padding-left: 12px;
          }
          h3 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 26px;
          }
          strong {
            font-weight: 700;
          }
        `}</style>
      </Fragment>
    </Layout>
  )
}

export default SuccessDemo
