import React, { Fragment } from "react"
import PropTypes from "prop-types"

const Search = ({ onSearch, searchValue, searchPlaceholder }) => {
  return (
    <Fragment>
      <div className="w-100 search-box">
        <div>
          <input
            type={"text"}
            className="search-input"
            value={searchValue}
            placeholder={searchPlaceholder}
            onChange={event => {
              onSearch(event.target.value)
            }}
          />
        </div>
      </div>
      <style jsx="true">{`
        .search-input {
          height: 20px;
          border-width: 1px;
          border-radius: 8px;
          border-color: #cccccc;
          width: 100%;
          padding: 16px;
          font-size: 13px;
          display: inline-block;
        }
        .navbar-web {
          --bs-navbar-padding-y: 1.5rem;
        }
      `}</style>
    </Fragment>
  )
}

Search.propTypes = {
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
}

export default Search
