import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Nav from "../../components/nav"
import BrandCard from "../../components/brandCard"
import Layout from "../../components/layout"
import { brands } from "../../data/brands"
import brandFitbit from "../../images/brand-fitbit-square.png"
import brandWithings from "../../images/brand-withings-square.png"
import brandPolar from "../../images/brand-polar-square.png"
import brandGarmin from "../../images/brand-garmin-square.png"
import brandGooglefit from "../../images/brand-googlefit-square.png"
import brandApple from "../../images/brand-apple-square.png"
import brandFreestyle from "../../images/brand-freestyle-square.png"
import brandHuawei from "../../images/brand-huaweihealth-square.png"
import brandOmron from "../../images/brand-omron-square.png"
import brandOura from "../../images/brand-oura-square.png"
import brandSamsung from "../../images/brand-samsung-square.png"
import brandWhoop from "../../images/brand-whoop-square.png"
import Search from "../../components/search"
import WhyVivantaInvolved from "../../components/whyVivantaInvolved"

const BrandsDemo = () => {
  const [search, setSearch] = useState("")
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getBrandImage = name => {
    if (name === "Fitbit") return brandFitbit
    if (name === "Withings") return brandWithings
    if (name === "Polar") return brandPolar
    if (name === "Garmin") return brandGarmin
    if (name === "Google") return brandGooglefit

    if (name === "Apple") return brandApple
    if (name === "Freestyle Libre") return brandFreestyle
    if (name === "Huawei Health") return brandHuawei
    if (name === "Omron") return brandOmron
    if (name === "Oura") return brandOura
    if (name === "Samsung") return brandSamsung
    if (name === "Whoop") return brandWhoop
  }

  const redirectToSuccess = code => {
    navigate(`/success/${code}`)
  }

  brands.sort((a, b) => (a.name > b.name ? 1 : -1))

  const filteredBrands =
    search !== ""
      ? brands.filter(brand =>
          brand.name.toLowerCase().includes(search.toLowerCase())
        )
      : brands

  return (
    <Layout>
      <React.Fragment>
        <Nav
          back={"/"}
          title={t("brands.title")}
          searchPlaceholder={t("brands.search")}
          searchValue={search}
          onSearch={text => setSearch(text)}
        />
        <div className="brand-container">
          <div className="row mt-2">
            <div className="col-12 mb-3">
              <Search
                searchPlaceholder={t("brands.search")}
                searchValue={search}
                onSearch={text => setSearch(text)}
              />
            </div>
            {filteredBrands.map(brand => (
              <div className="col-6" key={`brand-card-${brand.id}`}>
                <BrandCard
                  id={brand.id}
                  image={getBrandImage(brand.name)}
                  title={brand.name}
                  handleClick={redirectToSuccess}
                />
              </div>
            ))}
          </div>
        </div>
        <WhyVivantaInvolved />
        <style jsx="true">
          {`
            .brand-container {
              margin-right: 0.5rem;
            }
          `}
        </style>
      </React.Fragment>
    </Layout>
  )
}

export default BrandsDemo
