import { Fragment } from "react"
import Layout from "../../components/layout"
import Nav from "../../components/nav"

const AboutVivanta = () => {
  return (
    <Layout>
      <Fragment>
        <Nav back={"/choose-brand"} />
        <div className="container">
          <div className="row mt-2">
            <div className="col-12">
              <h4 className="">About Vivanta</h4>
              <p>
                Vivanta lets you connect your smartphone, smartwatch or health
                device. This is a service provided by Vivanta. The connection
                Vivanta provides to your device account(s) does not imply
                affiliation with any company.
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    </Layout>
  )
}

export default AboutVivanta
