import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../components/layout"
import vivantaLogo from "../../images/vivanta-logo.png"
import success from "../../images/success-integration.png"
import { useParams } from "react-router-dom"

const IntegrationSuccess = () => {
  const { t } = useTranslation()
  const clientName = localStorage.getItem("clientName")
  const returnToLink = localStorage.getItem("returnToLink") ?? ""
  const { brand } = useParams()
  let brandName = ""
  if (brand === "fitbit") brandName = "Fitbit"
  if (brand === "withings") brandName = "Withings"
  if (brand === "polar") brandName = "Polar"
  if (brand === "garmin") brandName = "Garmin"
  if (brand === "googlefit" || brand === "google-fit") brandName = "Google Fit"

  const isMobile = localStorage.getItem("isMobile")

  const closeWindow = () => {
    window.opener = null
    window.open("", "_self")
    window.close()
  }

  return (
    <Layout>
      <Fragment>
        <div className="flex-grow-1">
          <div className="row">
            <div className="col-8 offset-2">
              <img src={vivantaLogo} alt="Vivanta" className="img-fluid logo" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <h3 className="text-center">
                <strong>{clientName}</strong>
                {t("success.message", { brandName })}
              </h3>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6 offset-3">
              <img src={success} alt="Success" className="img-fluid" />
            </div>
          </div>
        </div>
        {!parseInt(isMobile) === 1 && (
          <div className="flex-shrink-1">
            <div className="container">
              <div className="d-grid">
                <button
                  onClick={closeWindow}
                  className="btn btn-primary btn-block mb-4"
                >
                  {t("success.button")}
                </button>
              </div>
            </div>
          </div>
        )}
        {parseInt(isMobile) === 1 && returnToLink !== "" && (
          <div className="flex-shrink-1">
            <div className="container">
              <div className="d-grid">
                <a
                  href={returnToLink}
                  className="btn btn-primary btn-block mb-4"
                >
                  {t("success.return")}
                </a>
              </div>
            </div>
          </div>
        )}
        <style jsx="true">{`
          .logo {
            margin-top: 84px;
            margin-bottom: 24px;
          }
          .outside-icon {
            padding-left: 12px;
          }
          h3 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 26px;
          }
          strong {
            font-weight: 700;
          }
        `}</style>
      </Fragment>
    </Layout>
  )
}

export default IntegrationSuccess
