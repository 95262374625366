import React, { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import Loading from "../components/loading"
import Api from "../network/api"

const SignedLanding = () => {
  const { token, brandId } = useParams()
  const [searchParams] = useSearchParams()
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    if (!isAuth) {
      localStorage.setItem("auth-token", token)
      localStorage.setItem(
        "isNative",
        searchParams.get("native")?.toLowerCase() === "true" ? "1" : "0"
      )
      const api = new Api()
      api.getAuthenticatedUser(token).then(result => {
        if (result !== undefined) {
          localStorage.setItem("userId", result.id)
          localStorage.setItem("externalId", result.externalId)
          localStorage.setItem("token", token)
          localStorage.setItem("clientName", result.client.name)
          localStorage.setItem("returnToLink", result.client.returnToLink ?? "")
          localStorage.setItem("isMobile", searchParams.get("web") ? "0" : "1")
          if (brandId) {
            localStorage.setItem("brandId", brandId)
            localStorage.setItem("isNative", "1")
            document.location.href = `/choose-brand/${brandId}`
          } else {
            setIsAuth(true)
          }
        }
      })
    }

    return () => {}
  }, [])

  return isAuth ? <Navigate to={"/start"} replace={true} /> : <Loading />
}

export default SignedLanding
