import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          start: {
            title: " uses Vivanta to connect your device",
            message: "Vivanta will only be able to read your data",
            button: "Continue",
            legal1: "By hitting continue you are accepting the ",
            legal2: "Privacy Policy of Vivanta",
          },
          brands: {
            title: "Connect your device",
            search: "Find Brand",
          },
          success: {
            message:
              " is now connected to your {{brandName}}. You can now close this window.",
            button: "Close",
            return: "Return",
          },
          common: {
            loading: "Loading...",
          },
        },
      },
      es: {
        translation: {
          start: {
            title: " utiliza Vivanta para conectar tu dispositivo",
            message: "Vivanta solo podrá leer tus datos",
            button: "Continuar",
            legal1: "Al presionar el botón, estás aceptando la ",
            legal2: "Política de Privacidad de Vivanta",
          },
          brands: {
            title: "Conecta tu dispositivo",
            search: "Encuentra una marca",
          },
          success: {
            message:
              " está ahora conectado a tu {{brandName}}. Ya puedes cerrar esta pantalla.",
            button: "Cerrar",
            return: "Regresar",
          },
          common: {
            loading: "Cargando...",
          },
        },
      },
      pt: {
        translation: {
          start: {
            title: " usa Vivanta para conectar o seu dispositivo",
            message: "A Vivanta apenas poderá ler os seus dados",
            button: "Continuar",
            legal1: "Ao clicar em continuar, você está aceitando a ",
            legal2: "Política de Privacidade da Vivanta",
          },
          brands: {
            title: "Conecte o seu dispositivo",
            search: "Encontrar Marca",
          },
          success: {
            message:
              " agora está conectado ao seu {{brandName}}. Você pode fechar esta janela agora.",
            button: "Fechar",
            return: "Retornar",
          },
          common: {
            loading: "Carregando...",
          },
        },
      },
    },
  })

export default i18n
