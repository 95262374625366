import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="h-100" id="main">
        <div className="d-flex h-100 flex-column container">{children}</div>
      </div>
      <style jsx="true">{`
        .close-view {
          position: absolute;
          background: #ccc;
          width: 100%;
          height: 12px;
          z-index: 3000;
          cursor: pointer;
        }
        .close-view div {
          position: absolute;
          background: #000;
          height: 24px;
          width: 24px;
          border-radius: 12px;
          text-align: center;
          right: 0;
        }
      `}</style>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.element,
}

export default Layout
