import { Fragment } from "react"
import { Link } from "react-router-dom"

const WhyVivantaInvolved = () => {
  return (
    <Fragment>
      <div className="mt-4 mb-4 vivanta-involved-div">
        <Link className="link" to="/about-vivanta">
          Why is Vivanta involved?
        </Link>
      </div>
      <style jsx="true">{`
        .vivanta-involved-div {
          text-align: center;
        }
        .vivanta-involved-div a {
          font-weight: 300;
          font-size: 0.9rem;
          color: #999999;
          text-decoration: underline;
        }
      `}</style>
    </Fragment>
  )
}

export default WhyVivantaInvolved
