import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import vivantaLogo from "../../images/vivanta-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import Layout from "../../components/layout"

const Start = () => {
  const { t } = useTranslation()

  const FooterLegal = styled.p`
    font-size: 12px;
    color: #bbbbbb;
    text-align: center;
  `

  const FooterLegalA = styled.a`
    color: #bbbbbb;
    text-decoration: underline;
    font-weight: 500;
  `
  const [clientName, setClientName] = useState("")

  useEffect(() => {
    setClientName(localStorage.getItem("clientName") ?? "")

    return () => {}
  }, [])

  const isMobile = localStorage.getItem("isMobile")

  return (
    <Layout>
      <React.Fragment>
        <div className="flex-grow-1">
          <div className="row">
            <div className="col-8 offset-2">
              <img src={vivantaLogo} alt="Vivanta" className="img-fluid logo" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <h3 className="text-center mt-2">
                <strong>{clientName}</strong>
                {t("start.title")}
              </h3>
              <div className="row d-flex align-items-center">
                <div className="col-2">
                  <FontAwesomeIcon
                    icon={faLock}
                    size={"2x"}
                    className="color-vivanta-purple"
                  />
                </div>
                <div className="col-10">
                  <p className="caption">{t("start.message")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-1">
          <div className="container">
            <div className="d-grid">
              <Link to="/choose-brand" className="btn btn-primary btn-block">
                {t("start.button")}
              </Link>
            </div>
            {isMobile === "0" ? (
              <div className="d-grid mt-2 mb-3">
                <button
                  onClick={() => {
                    window.opener = null
                    window.open("", "_self")
                    window.close()
                  }}
                  className="btn btn-link"
                  style={{
                    textDecoration: "none",
                    fontSize: "0.8rem",
                    margin: "0.2rem 0",
                  }}
                >
                  Return
                </button>
              </div>
            ) : (
              <div className="mt-3"></div>
            )}
            <FooterLegal>
              {t("start.legal1")}{" "}
              <Link to="/privacy">
                <FooterLegalA className="footer-link">
                  {t("start.legal2")}
                </FooterLegalA>
              </Link>
              .
            </FooterLegal>
          </div>
        </div>
        <style jsx="true">{`
          .logo {
            margin-top: 84px;
            margin-bottom: 24px;
          }
          .outside-icon {
            padding-left: 12px;
          }
          h3 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 26px;
          }
          strong {
            font-weight: 700;
          }
        `}</style>
      </React.Fragment>
    </Layout>
  )
}

export default Start
