import React, { useEffect, useState } from "react"
import { useParams, Navigate } from "react-router-dom"
import Loading from "../../components/loading"
import Api from "../../network/api"
import { subdomainIsWidget } from "../../helpers/subdomain"

const BrandRedirect = () => {
  const { code, code2, brandCode } = useParams()
  const finalCode = code2 === undefined ? code : `${code}/${code2}`

  if (subdomainIsWidget()) {
    let url = `https://connect.vivanta.io/redirect/${brandCode}/${finalCode}`
    window.location.href = url
  }

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  let brandId = 0
  if (brandCode === "fitbit") brandId = 1
  if (brandCode === "withings") brandId = 2
  if (brandCode === "polar") brandId = 3
  if (brandCode === "garmin") brandId = 4
  if (brandCode === "whoop") brandId = 7
  if (brandCode === "googlefit" || brandCode === "google-fit") brandId = 5

  useEffect(() => {
    if (loading) {
      const api = new Api()
      api.saveAuthCode(brandId, finalCode).then(result => {
        if (result) {
          setLoading(false)
          setSuccess(true)
        }
      })
    }

    return () => {}
  }, [])

  if (success)
    return <Navigate to={`/integration-success/${brandCode}`} replace={true} />

  if (loading) return <Loading />
}

export default BrandRedirect
