import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import vivantaLogo from "../images/vivanta-logo.png"
import PropTypes from "prop-types"

const Nav = ({ back }) => {
  return (
    <Fragment>
      <nav className={"navbar container navbar-light navbar-expand-lg"}>
        <div className="d-flex justify-content-between w-100">
          <div className="flex-grow-0">
            <span>&nbsp;</span>
            <Link to={back}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="icon"
                size={"1x"}
              />
            </Link>
          </div>
          <div className="flex-grow-1 text-center">
            <img
              src={vivantaLogo}
              alt="Vivanta"
              className="img-fluid nav-logo"
            />
          </div>
          <div className="flex-grow-0">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="icon color-vivanta-white"
              size={"1x"}
            />
            <span>&nbsp;</span>
          </div>
        </div>
      </nav>
      <style jsx="true">{`
        .icon {
          color: #000000;
        }
        .search-input {
          height: 20px;
          border-width: 0 0 1px 0;
          border-color: #cccccc;
          width: 95%;
          padding: 4px;
          font-size: 13px;
        }
        .navbar-web {
          --bs-navbar-padding-y: 1.5rem;
        }
      `}</style>
    </Fragment>
  )
}

Nav.propTypes = {
  back: PropTypes.string,
  title: PropTypes.string,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
}

export default Nav
