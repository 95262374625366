import React from "react"
import PropTypes from "prop-types"

const BrandCard = ({ id, image, title, handleClick }) => {
  return (
    <div className="cursor mb-3">
      <div className="card p-2" style={{ width: "100%" }}>
        <a onClick={() => handleClick(id)}>
          <img src={image} className="card-img-top img-fluid" alt={title} />
        </a>
        {title !== undefined && (
          <div>
            <p className="text-center mt-2 mb-1 brandName">{title}</p>
          </div>
        )}
      </div>
      <style jsx="true">
        {`
          .cursor {
            cursor: pointer;
          }
          .brandName {
            font-size: 0.8rem;
          }
        `}
      </style>
    </div>
  )
}

BrandCard.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  title: PropTypes.string,
  handleClick: PropTypes.func,
}

export default BrandCard
