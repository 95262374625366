import axios from "axios"

class Api {
  url = process.env.REACT_APP_API_URL

  authenticateUser = async (externalId, externalCompanyId = undefined) => {
    const headers = {
      "x-api-key": localStorage.getItem("publicKey"),
      "x-customer": localStorage.getItem("customerId"),
    }

    try {
      const result = await axios.post(
        `${this.url}/users/auth`,
        {
          externalUserId: externalId,
          externalCompanyId: externalCompanyId.toString(),
        },
        {
          headers,
        }
      )

      return result.data
    } catch (ex) {
      return undefined
    }
  }

  authenticateToken = async token => {
    const headers = {
      "x-api-key": localStorage.getItem("publicKey"),
      "x-customer": localStorage.getItem("customerId"),
    }

    try {
      const result = await axios.post(
        `${this.url}/users/token-auth`,
        {
          token: token,
        },
        {
          headers,
        }
      )

      return result.data
    } catch (ex) {
      return undefined
    }
  }

  getAuthenticatedUser = async token => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    try {
      const result = await axios.get(`${this.url}/users/single/me`, {
        headers,
      })

      return result.data
    } catch (ex) {
      return undefined
    }
  }

  getBrands = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }

    try {
      const result = await axios.get(`${this.url}/brands/user/all`, {
        headers,
      })

      return result.data
    } catch (ex) {
      return undefined
    }
  }

  getBrandLink = async brandId => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }

    try {
      const result = await axios.post(
        `${this.url}/brands/${brandId}/link`,
        {},
        {
          headers,
        }
      )

      return result.data
    } catch (ex) {
      return undefined
    }
  }

  saveAuthCode = async (brandId, authCode) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }

    try {
      await axios.post(
        `${this.url}/users/access-tokens`,
        {
          brand_id: brandId,
          authorization_code: authCode,
        },
        {
          headers,
        }
      )

      return true
    } catch (ex) {
      return false
    }
  }
}

export default Api
