import React from "react"
import AppRouter from "./Routes"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import styled from "styled-components"

function App() {
  const Main = styled.div`
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    height: 100% !important;
  `

  return (
    <Main>
      <AppRouter />
    </Main>
  )
}

export default App
