import { DoubleBubble } from "react-spinner-animated"
import { useTranslation } from "react-i18next"
import "react-spinner-animated/dist/index.css"

const Loading = () => {
  const { t } = useTranslation()

  return (
    <DoubleBubble
      text={t("common.loading")}
      center={true}
      width={"100px"}
      height={"100px"}
    />
  )
}

export default Loading
