import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"
import Layout from "../../components/layout"
import Nav from "../../components/nav"

const Privacy = () => {
  const externalId = localStorage.getItem("externalId")
  const publicKey = localStorage.getItem("publicKey")
  const customerId = localStorage.getItem("customerId")
  const [searchParams] = useSearchParams()
  const isNative =
    searchParams.get("native")?.toLowerCase() === "true"
      ? "1"
      : localStorage.getItem("isNative")
  return (
    <Layout>
      <Fragment>
        {isNative === "0" && (
          <Nav back={`/start/${publicKey}/${customerId}/${externalId}`} />
        )}
        <div className="container">
          <div className="row mt-2">
            <div className="col-12">
              <h3>Privacy Policy</h3>
              <hr />
              <h4>INTRODUCTION</h4>
              Vivanta Inc., operates https://vivanta.io (hereinafter referred to
              as “The Company”). Our privacy policy governs https://vivanta.io
              and explains how The Company collects, safeguards, and discloses
              information that results from your use of our service.
              <br />
              <br />
              The Company uses your data to provide and improve our service. By
              using The Company’s service, you agree to the collection and use
              of information in accordance with this policy.
              <br />
              <br />
              <h4>TYPES OF DATA COLLECTED</h4>
              <h4>Personal Data</h4>
              <br />
              While using The Company’s service, The Company may ask you to
              provide The Company with certain personally identifiable
              information that can be used to contact or identify You.
              Personally identifiable information may include, but is not
              limited to: email address and first name and last name.
              <br />
              <br />
              <h4>Usage Data</h4>
              <br />
              Usage Data may include information such as your device’s Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that you visit, the time and date of your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
              <br />
              <br />
              When you access The Company’s service by or through a mobile
              device, we may collect certain information automatically,
              including, but not limited to, the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
              <br />
              <br />
              The Company may also collect information that your browser sends
              whenever you visit The Company’s service or when you access the
              service by or through a mobile device.
              <br />
              <br />
              <h4>Location Data</h4>
              <br />
              The Company may use and store information about your location if
              you give The Company permission to do so (“Location Data”). The
              Company uses this data to provide features of our service, to
              improve and customize our service.
              <br />
              <br />
              You can enable or disable location services when you use our
              service at any time by way of your device settings.
              <br />
              <br />
              <h4>Cookies</h4>
              <br />
              The Company uses Cookies and similar tracking technologies to
              track the activity on our service and store certain information.
              Tracking technologies used are beacons, tags, and scripts to
              collect and track information and to improve and analyze The
              Company’s service.
              <br />
              <br />
              <h4>USE OF DATA</h4>
              The Company may use personal data for the following purposes:
              <br />
              <br />
              To provide and maintain The Company’s service, including
              monitoring the usage of our service.
              <br />
              <br />
              To manage your account: To manage your registration as a user of
              the service.
              <br />
              <br />
              The personal data you provide can give you access to different
              functionalities of the Service that are available to you as a
              registered user.
              <br />
              <br />
              For the performance of a contract: the development, compliance,
              and undertaking of the purchase contract for the products, items,
              or services you have purchased or of any other contract with us
              through the service.
              <br />
              <br />
              To contact you: by email, telephone calls, SMS, or other
              equivalent forms of electronic communication, such as a mobile
              application’s push notifications regarding updates or informative
              communications related to the functionalities, products or
              contracted services, including the security updates, when
              necessary or reasonable for their implementation.
              <br />
              <br />
              To provide you: with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
              <br />
              <br />
              To manage your requests: attend and manage your requests to Us.
              <br />
              <br />
              For business transfers: The Company may use your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of The Company’s assets, whether as a going concern or as part
              of bankruptcy, liquidation, or similar proceeding, in which
              personal data held by The Company about our service users is among
              the assets transferred.
              <br />
              <br />
              For other purposes: The Company may use your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improving our service, products, services, marketing,
              and your experience.
              <br />
              <br />
              The Company may share your personal information in the following
              situations:
              <br />
              <br />
              With service providers: The Company may share your personal
              information with service providers to monitor and analyze the use
              of our service to contact You.
              <br />
              <br />
              For business transfers: The Company may share or transfer your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of The Company’s business to another company.
              <br />
              <br />
              With Affiliates: The Company may share your information with our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include the parent company
              and any other subsidiaries, joint venture partners or other
              companies that The Company controls or that are under common
              control with Us.
              <br />
              <br />
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
              <br />
              <br />
              With other users: when you share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
              <br />
              <br />
              With your consent: The Company may disclose your personal
              information for any other purpose with Your consent.
              <br />
              <br />
              <h4>RETENTION OF DATA</h4>
              The Company will retain your personal data only for as long as is
              necessary for the purposes set out in this Privacy Policy. The
              Company will retain and use your personal data to the extent
              necessary to comply with our legal obligations (for example, if we
              are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce The Company’s legal agreements and
              policies.
              <br />
              <br />
              We will also retain usage data for internal analysis purposes.
              usage data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of The Company’s service, or we are legally
              obligated to retain this data for longer time periods.
              <br />
              <br />
              <h4>TRANSFER OF DATA</h4>
              Your information, including personal data, is processed at The
              Company’s operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from your jurisdiction.
              <br />
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
              <br />
              <br />
              The Company will take all steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your personal data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
              <br />
              <br />
              <h4>DISCLOSURE OF DATA</h4>
              The Company may disclose personal information that we collect, or
              you provide:
              <br />
              <br />
              <h4>Disclosure for Law Enforcement.</h4>
              <br />
              Under certain circumstances, The Company may be required to
              disclose your personal data if required to do so by law or in
              response to valid requests by public authorities.
              <br />
              <br />
              <h4>Business Transaction.</h4>
              <br />
              If The Company or our subsidiaries are involved in a merger,
              acquisition or asset sale, your personal data may be transferred.
              <br />
              <br />
              Other cases.
              <br />
              <br />
              The Company may disclose your information also: to our
              subsidiaries and affiliates; to fulfill the purpose for which you
              provide it; for any other purpose disclosed by The Company when
              you provide the information; with your consent in any other cases;
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of The Company, The Company’s
              customers, or others.
              <br />
              <br />
              <h4>SECURITY OF THE DATA</h4>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure.
              <br />
              While we strive to use commercially acceptable means to protect
              your Personal Data, we cannot guarantee its absolute security.
              <br />
              <br />
              <h4>CHILDREN’S POLICY</h4>
              The Service is for general audiences and is not directed toward
              those under 18 years of age. The Company do not knowingly collect
              personal information from children under 13 without parental
              consent. If you become aware that a child has provided us with
              personal information, please contact The Company at the email
              address in the Contact Information section. If we become aware
              that a child under 13 has provided us with personal information,
              we will take steps to remove such information and terminate the
              child’s account.
              <br />
              <br />
              <h4>LINKS TO OTHER WEBSITES</h4>
              Our service may contain links to other sites that are not operated
              by The Company. If you click a third-party link, you will be
              directed to that third-party’s site. We strongly advise you to
              review the Privacy Policy of every site you visit.
              <br />
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third-party sites or
              services.
              <br />
              <br />
              <h4>CHANGES TO PRIVACY POLICY</h4>
              The Company may revise this Privacy Policy from time to time. Any
              such change will be effective immediately upon posting on the
              Website. You are responsible for checking the Privacy Policy for
              such changes.
              <br />
              <br />
              <h4>CONTACT</h4>
              alex@vivanta.io
              <br />
              <br />
            </div>
          </div>
        </div>
      </Fragment>
    </Layout>
  )
}

export default Privacy
